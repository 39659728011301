<template>
<div class="pageInner">
<div class="img noDrag"><img src="img/seojun1.png" loading="lazy"></div>
<div>
    <div class="noDrag">
        <div class="name"><h2 class="name1">서준</h2><!-- <h2 class="name2">서준</h2> --></div>
        <h3 class="nameEn">Seojun</h3>
    </div>
    <div class="aboutMe">안녕하세요<br>박서준입니다.</div>
</div>
</div>
</template>

<style scoped src="../../public/css/mainPage.css"></style>