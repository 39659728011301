<template>
    <nav class="noDrag ui ui_right">
        <div class="nav navHighlight">
            <router-link to="/">Home</router-link>
            <div class="navLine"></div>
        </div>
        <div class="nav">
            <router-link to="/about">About</router-link>
            <div class="navLine"></div>
        </div>
        <div class="nav" style="margin-bottom: 70px;">
            <router-link to="/project">project</router-link>
            <div class="navLine"></div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'app',
    watch: {
        '$route'(to) {
            if (to.fullPath == "/") {
                document.querySelectorAll(".nav")[0].classList = "nav navHighlight";
                document.querySelectorAll(".nav")[1].classList = "nav";
                document.querySelectorAll(".nav")[2].classList = "nav";
            }
            if (to.fullPath == "/about") {
                document.querySelectorAll(".nav")[0].classList = "nav";
                document.querySelectorAll(".nav")[1].classList = "nav navHighlight";
                document.querySelectorAll(".nav")[2].classList = "nav";
            }
            if (to.fullPath == "/project") {
                document.querySelectorAll(".nav")[0].classList = "nav";
                document.querySelectorAll(".nav")[1].classList = "nav";
                document.querySelectorAll(".nav")[2].classList = "nav navHighlight";
            }
        }
    }
    };
</script>