<template>
  <div class="waSans">
    <uiLeft/>
    <uiRight/>
    <div class="page">
      <transition name="slide-fade" mode="out-in">
        <router-view/>
      </transition>
    </div>
  </div>
</template>

<style>
  @import "/css/main.css";
  @import "/css/ui.css";

  .slide-fade-enter-from {
    transform: translateY(10px);
    opacity: 0;
  }

  .slide-fade-enter-to {
    transform: translateY(0px);
    opacity: 1;
  }

    .slide-fade-leave-from {
    transform: translateY(0px);
    opacity: 1;
  }

  .slide-fade-leave-to {
    transform: translateY(-10px);
    opacity: 0;
  }

  .slide-fade-enter-active,
  .slide-fade-leave-active {
    transition: all 0.5s;
  }
</style>

<script>
  import uiLeft from './components/uiLeft.vue';
  import uiRight from './components/uiRight.vue';

  export default {
    name: 'App',
    components: { uiLeft, uiRight }
  };
</script>