import routes from "./router/router.js"
import { createApp } from 'vue'
import {createRouter, createWebHistory} from "vue-router";
import App from './App.vue'

const router = createRouter({
    history: createWebHistory(),
    routes,
    /* scrollBehavior(to, from, savedPosition) {
      return savedPosition || new Promise((resolve) => {
        setTimeout(()=> resolve({top:0, behavior: "instant"}), 300)
      })
    } */
  
})

const app = createApp(App)
  .use(router)
app.mount('#app')