<template>
<div class="pageInner1">
<h1 class="noDrag">Project</h1>
<div class="pageInner2">
    <div class="projects">

        <div class="project">
            <div class="noDrag projBack"><img src="img/project/portfolio.png" loading="lazy"></div>
            <div class="projTop">
                <h2>포트폴리오 모음</h2>
                <!-- <img class="noDrag icon" src="img/icon/1.png"> -->
            </div>
            <h5>포트폴리오 모음집<br>개선버전</h5>
            <div class="noDrag projBottom"><a href="https://seojun.xyz/portfolio">바로가기<img src="img/link.svg" loading="lazy"></a></div>
        </div>

        <div class="project">
            <div class="noDrag projBack"><img src="img/project/history.png" loading="lazy"></div>
            <div class="projTop">
                <h2>한국사 문제집</h2>
                <img class="noDrag icon" src="img/icon/1.png" loading="lazy">
            </div>
            <h5>한국사 시험대비<br>웹페이지</h5>
            <div class="noDrag projBottom"><a href="https://seojun.xyz/history">바로가기<img src="img/link.svg" loading="lazy"></a></div>
        </div>

        <div class="project">
            <div class="noDrag projBack"><img src="img/project/genesis.png" loading="lazy"></div>
            <div class="projTop">
                <h2>라믹 스튜디오</h2>
                <img class="noDrag icon" src="img/icon/ramicstudio.jpg" loading="lazy">
            </div>
            <h5>라믹스튜디오<br>바로가기</h5>
            <div class="noDrag projBottom"><a href="https://ramicstudio.com">바로가기<img src="img/link.svg" loading="lazy"></a></div>
        </div>

        <div class="project">
            <div class="noDrag projBack"><img src="img/project/history.png" loading="lazy"></div>
            <div class="projTop">
                <h2>HTML5 학습<br> </h2>
                <!-- <img class="noDrag icon" src="img/icon/1.png"> -->
            </div>
            <h5>HTML5 학습<br>연습 문제집</h5>
            <div class="noDrag projBottom"><a href="https://seojun.xyz/htmltest">바로가기<img src="img/link.svg" loading="lazy"></a></div>
        </div>

        <div class="project">
            <div class="noDrag projBack"><img src="img/project/company.png" loading="lazy"></div>
            <div class="projTop">
                <h2>기업형 웹페이지</h2>
                <!-- <img class="noDrag icon" src="img/icon/1.png"> -->
            </div>
            <h5><br>기업형 일반 웹 페이지</h5>
            <div class="noDrag projBottom"><a href="https://seojun.xyz/company">바로가기<img src="img/link.svg" loading="lazy"></a></div>
        </div>

        <div class="project">
            <div class="noDrag projBack"><img src="img/project/miniblog.png" loading="lazy"></div>
            <div class="projTop">
                <h2>블로그 웹페이지</h2>
                <!-- <img class="noDrag icon" src="img/icon/1.png"> -->
            </div>
            <h5>모든 사람이 쓸수있는<br>미니블로그!</h5>
            <div class="noDrag projBottom"><a href="https://blog.seojun.xyz">바로가기<img src="img/link.svg" loading="lazy"></a></div>
        </div>
        <div class="project" style="background: rgb(5, 5, 5);">
            <div class="projTop">
                <h2>준비중</h2>
            </div>
            <h5>앞으로 더욱 정교한 프로젝트로<br>찾아뵙겠습니다.</h5>
            <div class="projBottom"></div>
        </div>
    </div>
</div>
</div>
</template>

<style scoped src="../../public/css/projectPage.css"></style>