import main from '../views/main.vue'
import about from '../views/about.vue'
import project from '../views/project.vue'

let routes = [
  { path: '/', component: main },
  { path: '/about', component: about },
  { path: '/project', component: project }
];



export default routes;